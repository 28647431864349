'use client';

import React from 'react'
import { Button } from './ui/button';
import { LocaleConstants } from '@/lib/react/i18n/constants/LocaleConstants';
import { useTranslation } from '@/lib/react/i18n';

type CustomComponentProps = React.ComponentProps<'div'> & {
	customProp?: string;
}

const JpButton: React.FC<CustomComponentProps> = ({ customProp, className, style, ...props }) => {
	const { setLocale, locale } = useTranslation();

	const clickJp = () => {
		setLocale('ja');
		if (typeof window !== "undefined") {
			window.localStorage.setItem(LocaleConstants.LOCALSTORAGE_KEYS.DEFAULT_LOCAL, 'ja');
		}
	}

	return (
		<Button
			className={className}
			style={locale === 'ja'
				? {
					backgroundColor: 'rgb(209 213 219 / var(--tw-bg-opacity))',
					color: '#000'
				}
				: {
					backgroundColor: 'rgb(100 100 100 / var(--tw-bg-opacity))',
					color: '#ffffff'
				}
			}
			onClick={clickJp}>JP</Button>
	)
}

export default JpButton
