"use client";

import { mutate } from "swr";
import { UserAPI, UserHideNameRequest } from "@/restapi/user";

export const useUserHideNameUpdate = () => {
  const submit = async (request: UserHideNameRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const restData = await UserAPI.updateHideName(request);

      // キャッシュを更新
      mutate(
        `user-update-hide-name-${JSON.stringify(request)}`,
        restData,
        false
      );

      return restData;
    } catch (err) {
      console.error("Failed to submit user:", err);
      throw err;
    }
  };

  return {
    submit,
  };
};
