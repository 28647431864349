import UserActiveIcon from "../icons/UserActiveIcon";
import { useNotifyGetNewPostedImage } from "@/hooks/notification/useNotification";
import { useSession } from "next-auth/react";

export const UserActivLink = () => {
	const {data:session} = useSession();
	const user = session?.user;
	const { 
		item,
		isLoading,
		isError,
	} = useNotifyGetNewPostedImage(user?.id ?? '');

	if (isLoading || isError) {
		return <UserActiveIcon />
	}

	if (!item || 1 > item.count) {
		return <UserActiveIcon />
	}

	return <UserActiveIcon />;
};

export default UserActivLink;