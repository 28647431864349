import React from 'react';

/**
 * パスワード表示・表示の目のアイコン
 */
export default function PasswordShowIcon (props: any) {
  return (
    <svg {...props}
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
        </style>
      </defs>
      <g>
        <path className="cls-2" d="M50,23c16.026,0,31.858,16.14,39.759,26.395.276.359.276.851,0,1.21-7.906,10.261-23.737,26.396-39.759,26.396s-31.858-16.14-39.759-26.395c-.276-.359-.276-.851,0-1.21,7.906-10.261,23.737-26.396,39.759-26.396M50,15C24.445,15,3.368,44.974.366,49.445c-.227.338-.227.772,0,1.11,3.002,4.471,24.079,34.445,49.634,34.445s46.632-29.974,49.634-34.445c.227-.338.227-.772,0-1.11-3.002-4.471-24.079-34.445-49.634-34.445h0Z" />
        <path className="cls-2" d="M50,65.85c-8.739,0-15.85-7.11-15.85-15.85s7.11-15.85,15.85-15.85,15.85,7.11,15.85,15.85-7.11,15.85-15.85,15.85ZM50,42.15c-4.328,0-7.85,3.521-7.85,7.85s3.521,7.85,7.85,7.85,7.85-3.521,7.85-7.85-3.521-7.85-7.85-7.85Z" />
      </g>
      <rect className="cls-1" />
    </svg>
  );
}