/**
 * v0 by Vercel.
 * サインアウトアイコン
 */
export default function SignOutIcon (props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<g>
				<path className="cls-2" d="M43.56,1v17.114c0,.552.448,1,1,1h6c.552,0,1-.448,1-1v-9.114c0-.552.448-1,1-1h38.44c.552,0,1,.448,1,1v82c0,.552-.448,1-1,1h-38.44c-.552,0-1-.448-1-1v-9.114c0-.552-.448-1-1-1h-6c-.552,0-1,.448-1,1v17.114c0,.552.448,1,1,1h54.44c.552,0,1-.448,1-1V1c0-.552-.448-1-1-1h-54.44c-.552,0-1,.448-1,1Z" />
				<path className="cls-2" d="M68.423,45H21.142c-.891,0-1.337-1.077-.707-1.707l15.643-15.643c1.953-1.952,1.953-5.118,0-7.07-1.951-1.953-5.119-1.953-7.07,0L3.122,46.463l-.002.002-2.828,2.828c-.391.391-.391,1.024,0,1.414l28.715,28.715c.976.977,2.256,1.465,3.535,1.465s2.56-.488,3.535-1.465c1.953-1.952,1.953-5.118,0-7.07l-15.643-15.644c-.63-.63-.184-1.707.707-1.707h47.496c2.924,0,5.261-2.508,4.977-5.491-.248-2.6-2.579-4.509-5.191-4.509Z" />
			</g>
			<rect className="cls-1" width="100" height="100" />
		</svg>
	);
}
