/**
 * v0 by Vercel.
 * 王冠アイコン
 */
export default function CrownActiveIcon (props: any) {
  return (
    <svg
      {
      ...props
      }
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
        </style>
      </defs>
      <g>
        <circle className="cls-2" cx="50" cy="7.131" r="7.131" />
        <circle className="cls-2" cx="7.131" cy="7.131" r="7.131" />
        <circle className="cls-2" cx="92.869" cy="7.131" r="7.131" />
        <path className="cls-2" d="M65.918,48.908l-15.059-25.323c-.388-.652-1.331-.652-1.719,0l-15.059,25.323c-.344.579-1.15.659-1.601.16L10.196,24.421c-.65-.718-1.839-.186-1.736.777l7.886,73.909c.054.508.483.894.994.894h65.32c.511,0,.94-.386.994-.894l7.886-73.909c.103-.963-1.087-1.495-1.736-.777l-22.284,24.647c-.451.499-1.257.419-1.601-.16Z" />
      </g>
      <rect className="cls-1" />
    </svg>
  );
}
