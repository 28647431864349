"use client";

import { FC, useState } from "react";
import { usePathname } from "next/navigation";
import ImageUploadModal from "@/components//Pict/ImageUploadModal";
import { useAppContext } from "@/context/AppContextProvider";
import { User } from "next-auth";

import UsersActiveIcon from "./icons/UsersActiveIcon";
import UsersDeactiveIcon from "./icons/UsersDeactiveIcon";
import CrownActiveIcon from "@/components//icons/CrownActiveIcon";
import CrownDeactiveIcon from "@/components/icons/CrownDeactiveIcon";
import PlusActiveIcon from "./icons/PlusActiveIcon";
import PlusDivactiveIcon from "./icons/PlusDivactiveIcon";
import { URLConstants } from "@/constants/URLConstants";
import UserActivLink from "./mypage/UserActivLink";
import UserDeActiveLink from "./mypage/UserDeActiveLink";
import Link from "next/link";
import HomeLink from "./home/link/HomeLink";
import "@/components/footer.css";

const Footer: FC<{ user: User | undefined }> = ({ user }) => {
  const {
    setSettingMenuProps,
    visableMode,
    isMobile,
    isModalOpen,
    setImageDisplayMode,
  } = useAppContext();
  const [isImgHistoryHovered, setIsImgHistoryHovered] = useState(false);
  const [isScoreHistoryHovered, setIsScoreHistoyHovered] = useState(false);
  const [isCrownHovered, setIsCrownHovered] = useState(false);
  const [isPlusHovered, setIsPlusHovered] = useState(false);
  const pathname = usePathname();

  const handleClick = () => {
    setSettingMenuProps({
      isMeneuOpen: false,
    });
    // リンクを踏むと画像一覧をデフォルトの3列表示に戻す
    setImageDisplayMode("RankingMode");
  };

  return (
    <div
      className={`bg-black fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full min-w-screen-sm wrapFootNaviArea ${
        visableMode.isShow ? "fade-in" : "fade-out"
      }`}
    >
      <ul className="flex justify-around bg-opacity-50 py-4 z-50 footNaviArea">
        <li className="flex flex-col items-center footNavi footNavi01">
          <HomeLink />
        </li>
        <li className="flex flex-col items-center footNavi footNavi02">
          <Link
            href={`${user ? URLConstants.user.mypage.pathname : "/signin"}`}
            className={`${isModalOpen ? "linkDisabled" : ""}`}
            onClick={handleClick}
            onMouseEnter={() => setIsImgHistoryHovered(true)}
            onMouseLeave={() => setIsImgHistoryHovered(false)}
          >
            {URLConstants.user.mypage.pathname === pathname ||
            (isImgHistoryHovered && !isMobile) ? (
              <UserActivLink />
            ) : (
              <UserDeActiveLink />
            )}
          </Link>
        </li>

        <li
          className="flex flex-col items-center footNavi footNavi03"
          onClick={handleClick}
        >
          {user ? (
            <ImageUploadModal />
          ) : (
            <Link
              className={`${isModalOpen ? "linkDisabled" : ""}`}
              href="/signin"
            >
              <PlusDivactiveIcon className="w-6 h-6" />
            </Link>
          )}
        </li>
        <li className="flex flex-col items-center footNavi footNavi04">
          <Link
            href={`${
              user ? URLConstants.user.scoring.history.pathname : "/signin"
            }`}
            className={`${isModalOpen ? "linkDisabled" : ""}`}
            onClick={handleClick}
            onMouseEnter={() => setIsScoreHistoyHovered(true)}
            onMouseLeave={() => setIsScoreHistoyHovered(false)}
          >
            {URLConstants.user.scoring.history.pathname === pathname ||
            (isScoreHistoryHovered && !isMobile) ? (
              <UsersActiveIcon />
            ) : (
              <UsersDeactiveIcon />
            )}
          </Link>
        </li>
        <li className="flex flex-col items-center  footNavi footNavi05">
          <Link
            href={URLConstants.ranking.pathname}
            className={`${isModalOpen ? "linkDisabled" : ""}`}
            onClick={handleClick}
            onMouseEnter={() => setIsCrownHovered(true)}
            onMouseLeave={() => setIsCrownHovered(false)}
          >
            {URLConstants.ranking.pathname === pathname ||
            (isCrownHovered && !isMobile) ? (
              <CrownActiveIcon />
            ) : (
              <CrownDeactiveIcon />
            )}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
