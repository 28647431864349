import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/App.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/context/AppContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/context/AuthContexProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/react/i18n/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/providers/NextAuthProvider.tsx");
