"use client";

import { mutate } from "swr";
import { ImageAPI, ImagePostRequest } from "@/restapi/image";

export const useImagePost = () => {
  const submit = async (imagePostRequest: ImagePostRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const res = await ImageAPI.post(imagePostRequest);

      // キャッシュを更新
      mutate(`image-post-${JSON.stringify(imagePostRequest)}`, res, false);

      return res;
    } catch (err) {
      console.error("Failed to submit post:", err);
      throw err; // エラー処理
    }
  };

  return {
    submit,
  };
};
