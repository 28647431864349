import UserDeactiveIcon from "../icons/UserDeactiveIcon";
import { useSession } from "next-auth/react";
import { useNotifyGetNewPostedImage } from "@/hooks/notification/useNotification";

export const UserDeActiveLink = () => {
	const {data:session} = useSession();
	const user = session?.user;
	const { 
		item,
		isLoading,
		isError,
	} = useNotifyGetNewPostedImage(user?.id ?? '');

	if (isLoading || isError) {
		return <UserDeactiveIcon />
	}

	if (!item || 1 > item.count) {
		return <UserDeactiveIcon />
	}

	return <UserDeactiveIcon />;
};

export default UserDeActiveLink;