/**
 * v0 by Vercel.
 * 利用規約
 */
export default function TeamOfServiceIcon (props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
		>
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<g>
				<g>
					<path className="cls-2" d="M67,87.061v3.939c0,.552-.448,1-1,1H9c-.552,0-1-.448-1-1V9c0-.552.448-1,1-1h57c.552,0,1,.448,1,1v36.945c0,.436.52.663.84.367l6.84-6.338c.204-.189.32-.455.32-.734V1c0-.552-.448-1-1-1H1C.448,0,0,.448,0,1v98c0,.552.448,1,1,1h73c.552,0,1-.448,1-1v-18.643c0-.436-.52-.663-.84-.367l-6.84,6.338c-.204.189-.32.455-.32.734Z" />
					<path className="cls-2" d="M50.646,46h6.389c2.055,0,3.909,1.471,4.152,3.511.246,2.075-1.099,3.875-2.974,4.357-.163.042-.338-.01-.461-.124l-7.444-6.876c-.334-.309-.116-.867.339-.867Z" />
					<path className="cls-2" d="M17.956,54h9.807c.211,0,.394-.134.463-.334.891-2.575,2.528-4.94,4.912-6.769.382-.293.186-.897-.295-.897h-15.06c-2.339,0-4.209,2.008-3.981,4.393.199,2.08,2.064,3.607,4.153,3.607Z" />
					<path className="cls-2" d="M17.956,77.186h21.046c.446,0,.669-.54.352-.855l-7.051-7c-.094-.093-.22-.145-.352-.145h-14.167c-2.339,0-4.209,2.008-3.981,4.393.199,2.08,2.064,3.607,4.153,3.607Z" />
					<path className="cls-2" d="M57.216,22.814H17.784c-2.209,0-4,1.791-4,4s1.791,4,4,4h39.432c2.209,0,4-1.791,4-4s-1.791-4-4-4Z" />
				</g>
				<path className="cls-2" d="M57.784,83.145l-20.102-19.956c-2.346-2.329-2.762-6.092-.743-8.71,2.44-3.166,7.005-3.39,9.748-.669l11.408,11.325c.38.378.991.388,1.384.024l29.603-27.429c2.839-2.629,7.394-2.244,9.72,1.006,1.924,2.689,1.377,6.434-1.049,8.681l-38.585,35.751c-.393.364-1.004.354-1.384-.024Z" />
			</g>
			<rect className="cls-1" width="100" height="100" />
		</svg>
	);
}