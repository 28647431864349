/**
 * v0 by Vercel.
 * プロファイル編集アイコン
 */
export default function EditProfileIcon (props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100" // ここをサンプルのviewBoxに合わせました
    >
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
        </style>
      </defs>
      <g>
        <g>
          <path className="cls-2" d="M67.281,79.774v11.226c0,.552-.45,1-1.004,1H9.038c-.555,0-1.004-.448-1.004-1V9c0-.552.45-1,1.004-1h58.243v14.005c0,.445.541.669.857.354l6.882-6.853c.188-.188.294-.442.294-.707V1c0-.552-.45-1-1.004-1H1.004C.45,0,0,.448,0,1v98c0,.552.45,1,1.004,1h73.306c.555,0,1.004-.448,1.004-1v-26.433c0-.445-.541-.669-.857-.354l-6.882,6.853c-.188.188-.294.442-.294.707Z" />
          <path className="cls-2" d="M13.842,73.186c0,2.209,1.799,4,4.017,4h11.756l.896-8h-12.653c-2.218,0-4.017,1.791-4.017,4Z" />
          <path className="cls-2" d="M17.859,30.814h39.597c2.218,0,4.017-1.791,4.017-4s-1.799-4-4.017-4H17.859c-2.218,0-4.017,1.791-4.017,4s1.799,4,4.017,4Z" />
          <path className="cls-2" d="M13.842,50c0,2.209,1.799,4,4.017,4h18.505l8.034-8h-26.538c-2.218,0-4.017,1.791-4.017,4Z" />
        </g>
        <path className="cls-2" d="M82.875,18.705l-43.556,43.374c-.161.16-.263.371-.288.596l-1.935,17.273c-.071.637.469,1.176,1.109,1.105l17.346-1.927c.226-.025.438-.126.599-.287l43.556-43.374c.392-.391.392-1.024,0-1.414l-15.411-15.346c-.392-.391-1.028-.391-1.42,0Z" />
      </g>
      <rect className="cls-1" width="100" height="100" />
    </svg>
  );
}
