"use client";

import { Inter } from "next/font/google";
import { User } from "next-auth";
const inter = Inter({ subsets: ["latin"] });
import Footer from "@/components/Footer";
import { bizUDPGothic } from "@/lib/utils/fonts";
import Header from "@/components/organisms/header";
import { usePathname } from "next/navigation";
import { URLConstants } from "@/constants/URLConstants";
import { useEffect, useState } from "react";
import { useAuthContext } from "@/context/AuthContexProvider";

export default function App({
  children,
  user,
}: Readonly<{
  children: React.ReactNode;
  user: User | undefined;
}>) {

  const pathname = usePathname();
  const isAvarageDetailPage = pathname.includes(
    `${URLConstants.user.average.pathname}/`
  );

  return (
    <body
      className={`${bizUDPGothic.className} flex flex-col min-h-screen bg-black`}
    >
      <div className="flex flex-col px-4 pc-container">
        {!isAvarageDetailPage && <Header />}
        {/* <InstallationButton /> */}
        <main>{children}</main>
        <Footer user={user} />
      </div>
    </body>
  );
}
