"use client";

import { useTranslation } from "@/lib/react/i18n";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import PasswordHideIcon from "./icons/PasswordHideIcon";
import PasswordShowIcon from "./icons/PasswordShowIcon";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import { useUserHideNameUpdate } from "@/hooks/user/useUserHideNameUpdate";

const HideMyID = () => {
  const { data: session } = useSession();
  const user = session?.user;
  const userId = user?.id ?? "";
  const router = useRouter();
  const { submit } = useUserHideNameUpdate();
  const {
    isError,
    isLoading,
    data: userProfile,
  } = useUserGetProfile({
    userUuid: userId,
  });

  const { t } = useTranslation();
  const [hideName, setHideName] = useState(userProfile?.hideName ?? false);

  useEffect(() => {
    if (!isLoading && !isError) {
      setHideName(userProfile?.hideName ?? false);
    }
  }, [isLoading, isError]);

  if (user === undefined) {
    router.push("/");
    return;
  }

  const toggleHideMyId = () => {
    try {
      if (userProfile) {
        submit({
          hideName: !hideName,
          userId,
        })
          .then((res) => {
            setHideName(res.hideName);
          })
          .catch((error) => {
            alert(error.message);
          });
      }
    } catch (error) {
      alert(t("account information does not exist."));
      return;
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
        <div className="mb-10 text-center">Loading...</div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-red-600 p-4">
        <div className="mb-10 text-center">Error</div>
      </div>
    );
  }

  return (
    <div
      className="hover:underline cursor-pointer tracking-wide settingNavi settingNavi04 "
      onClick={toggleHideMyId}
    >
      {hideName ? (
        <PasswordHideIcon className="inline-block mr-4 text-white w-6 h-6 tracking-wide" />
      ) : (
        <PasswordShowIcon className="inline-block mr-4 text-white w-6 h-6 tracking-wide" />
      )}
      {hideName ? t("Show my ID") : t("Hide my ID")}
    </div>
  );
};

export default HideMyID;
