

'use client'

import { URLConstants } from "@/constants/URLConstants";
import { useNotifyGetNewPostedImage } from "@/hooks/notification/useNotification";
import { useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

interface HomeDeactiveIconProps {
	color?: string;
	notificationCount?: number;
}


const HomeDeactiveIcon: React.FC<HomeDeactiveIconProps> = ({
	color = "#fff",
}) => {
	const [count, setCount] = useState(0);
	const { data: session } = useSession();
	const user = session?.user;
	const userId = user?.id ?? "";
	const { item } = useNotifyGetNewPostedImage(userId);

	useEffect(() => {
		if (item) {
			setCount(item?.count ?? 0);
		}
	}, [item]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 105 100">
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<path className="cls-2" d="M91.616,41.872c.242.19.384.48.384.788v48.34c0,.552-.448,1-1,1h-17c-.552,0-1-.448-1-1v-32.661c0-2.761-2.239-5-5-5h-36c-2.761,0-5,2.239-5,5v32.661c0,.552-.448,1-1,1H9c-.552,0-1-.448-1-1v-48.34c0-.308.142-.598.384-.788L49.384,9.827c.362-.283.87-.283,1.232,0l41,32.045ZM50,0c-.642,0-1.285.206-1.823.617L1.177,37.351c-.742.568-1.177,1.449-1.177,2.383v58.766c0,.828.672,1.5,1.5,1.5h32c.828,0,1.5-.672,1.5-1.5v-35.661c0-.828.672-1.5,1.5-1.5h27c.828,0,1.5.672,1.5,1.5v35.661c0,.828.672,1.5,1.5,1.5h32c.828,0,1.5-.672,1.5-1.5v-58.766c0-.934-.435-1.815-1.177-2.383L51.823.617c-.538-.412-1.18-.617-1.823-.617h0Z" />
			<rect className="cls-1" />
			{count > 0 && (
				<g transform="translate(63, 63)">
					<circle cx="20" cy="20" r="20" fill="red" />
					<text x="20" y="27" fontSize="20" fill="white" textAnchor="middle">
						{count}
					</text>
				</g>
			)}
		</svg>
	);
}

export default HomeDeactiveIcon;