import { signOut as nextAuthSignOut, useSession } from "next-auth/react";
import { signOut as firebaseSignOut } from "firebase/auth";
import { auth } from "@/lib/firebase/clientConfig";
import { useRouter } from "next/navigation";
import React from "react";
import SignOutIcon from "./icons/SignOutIcon";
import { deleteAllCookies } from "@/lib/utils/cookie-utils";
import { useTranslation } from "@/lib/react/i18n";

export default function SignOutButton () {
  const { data: session } = useSession();
  const user = session?.user;
  const router = useRouter();

  const { t } = useTranslation();

  const signOut = async () => {
    try {
      firebaseSignOut(auth);
      nextAuthSignOut({
        redirect: true,
      });
      router.push("/");

      // ログインできない場合クッキーとローカルストレージが悪さしてるっぽいので削除
      deleteAllCookies();
      localStorage.clear();

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="settingNavi settingNavi03"><a
      className="py-2 hover:underline text-lg text-white cursor-pointer"
      onClick={signOut}
    >
      <SignOutIcon className="inline-block mr-4 w-6 h-6" />
      {t("Sign Out")}
    </a></div>
  );
}
