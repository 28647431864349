/**
 * v0 by Vercel.
 * 画像アイコン
 */
export default function PlusDivactiveIcon (props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <path className="cls-2" d="M95.824,45.996h-40.818c-.553,0-1.001-.448-1.001-1.001V4.176c0-2.091-1.529-3.959-3.61-4.158-2.388-.228-4.398,1.644-4.398,3.985v40.991c0,.553-.448,1.001-1.001,1.001H4.176c-2.091,0-3.959,1.529-4.158,3.61-.228,2.388,1.644,4.398,3.985,4.398h40.991c.553,0,1.001.448,1.001,1.001v40.818c0,2.091,1.529,3.959,3.61,4.158,2.388.228,4.398-1.644,4.398-3.985v-40.991c0-.553.448-1.001,1.001-1.001h40.991c2.341,0,4.213-2.01,3.985-4.398-.199-2.082-2.066-3.61-4.158-3.61Z" />
      <rect className="cls-1" x="0" />
    </svg>
  );
}
