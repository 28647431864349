import React from 'react';

/**
 * パスワード表示・非表示の目のアイコン
 */
export default function PasswordHideIcon (props: any) {
  return (
    <svg {
      ...props
    }
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
        </style>
      </defs>
      <g>
        <g>
          <path className="cls-2" d="M50,34.285c-.526,0-1.045.032-1.556.088-.422.046-.606.558-.305.857l16.459,16.322c.3.297.807.113.854-.307.056-.509.089-1.024.089-1.548,0-8.512-6.958-15.412-15.541-15.412Z" />
          <path className="cls-2" d="M38.898,26.065c.288.286.718.362,1.092.204,3.076-1.295,6.458-2.017,10.011-2.017,14.17,0,25.658,11.392,25.658,25.445,0,3.518-.726,6.867-2.029,9.914-.162.378-.084.814.208,1.103l8.816,8.743c.389.386,1.023.387,1.413.003,8.47-8.339,14.201-17.028,15.601-19.232.208-.327.209-.735.002-1.062-2.867-4.52-24.011-36.426-49.669-36.426-6.273,0-12.272,1.915-17.804,4.864-.591.315-.699,1.121-.224,1.592l6.925,6.868Z" />
          <path className="cls-2" d="M61.102,73.329c-.288-.286-.717-.362-1.092-.204-3.076,1.295-6.458,2.017-10.01,2.017-14.17,0-25.658-11.392-25.658-25.445,0-3.518.726-6.867,2.029-9.914.162-.378.084-.814-.208-1.103l-8.816-8.743c-.389-.386-1.023-.387-1.413-.003C7.463,38.272,1.732,46.961.332,49.165c-.208.327-.209.735-.002,1.062,2.867,4.52,24.011,36.426,49.669,36.426,6.273,0,12.272-1.915,17.804-4.864.591-.315.699-1.121.224-1.592l-6.925-6.868Z" />
          <path className="cls-2" d="M50,65.109c.526,0,1.045-.032,1.556-.088.422-.046.606-.558.305-.857l-16.459-16.322c-.3-.297-.807-.113-.854.307-.056.509-.089,1.024-.089,1.548,0,8.512,6.958,15.412,15.541,15.412Z" />
        </g>
        <path className="cls-2" d="M86.801,91.802L8.166,13.82c-.394-.391-.394-1.029,0-1.42l4.236-4.202c.39-.387,1.019-.387,1.408,0l78.634,77.982c.394.391.394,1.029,0,1.42l-4.236,4.201c-.39.387-1.018.387-1.408,0Z" />
      </g>
      <rect className="cls-1" />
    </svg>
  );
}