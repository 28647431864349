/**
 * v0 by Vercel.
 * 王冠アイコン
 */
export default function CrownDeactiveIcon (props: any) {
  return (
    <svg
      {
      ...props
      }
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
        </style>
      </defs>
      <g>
        <path className="cls-2" d="M65.73,62.975l14.541-16.082c.325-.359.919-.093.868.388l-4.676,43.824c-.054.508-.483.894-.994.894H24.532c-.511,0-.94-.386-.994-.894l-4.676-43.824c-.051-.482.543-.748.868-.388l14.541,16.082c.226.25.629.209.801-.08l14.499-24.382c.194-.326.666-.326.86,0l14.499,24.382c.172.289.575.329.801.08ZM50,23.096c-.333,0-.666.163-.859.489l-15.059,25.323c-.344.579-1.15.659-1.601.16L10.196,24.421c-.208-.231-.473-.332-.732-.332-.548,0-1.074.455-1.004,1.109l7.886,73.909c.054.508.483.894.994.894h65.278c.511,0,.94-.385.994-.893l7.928-73.909c.07-.654-.456-1.109-1.004-1.109-.259,0-.523.102-.732.332l-22.284,24.647c-.451.499-1.257.419-1.601-.16l-15.059-25.323c-.194-.326-.527-.489-.859-.489h0Z" />
        <circle className="cls-2" cx="50" cy="7.131" r="7.131" />
        <circle className="cls-2" cx="7.131" cy="7.131" r="7.131" />
        <circle className="cls-2" cx="92.869" cy="7.131" r="7.131" />
      </g>
      <rect className="cls-1" />
    </svg>
  );
}
