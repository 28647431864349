/**
 * v0 by Vercel.
 * 画像アイコン
 */
export default function PlusActiveIcon (props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100"
    >
    <path className="cls-2" d="M50,0C22.386,0,0,22.386,0,50s22.386,50,50,50,50-22.386,50-50S77.614,0,50,0ZM86.566,54.5h-31.066c-.552,0-1,.448-1,1v31.066c0,2.35-1.718,4.449-4.058,4.672-2.684.256-4.942-1.848-4.942-4.479v-31.259c0-.552-.448-1-1-1H13.434c-2.35,0-4.449-1.718-4.672-4.058-.256-2.684,1.848-4.942,4.479-4.942h31.259c.552,0,1-.448,1-1V13.434c0-2.35,1.718-4.449,4.058-4.672,2.684-.256,4.942,1.848,4.942,4.479v31.259c0,.552.448,1,1,1h31.259c2.631,0,4.735,2.258,4.479,4.942-.223,2.34-2.322,4.058-4.672,4.058Z"/>
    <rect className="cls-1" /> 
    </svg >
  );
}
