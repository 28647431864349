"use client";

import { SessionProvider as NextAuthSessionProvider } from "next-auth/react";
import { Session } from "next-auth";

export interface SessionProviderProps {
  children: React.ReactNode;
  session: Session | null;
}

const NextAuthProvider = ({ children, session }: SessionProviderProps) => {
  return (
    <NextAuthSessionProvider session={session} refetchOnWindowFocus={false}>
      {children}
    </NextAuthSessionProvider>
  );
};

export default NextAuthProvider;
