'use client';
import React, { useContext, createContext, useState, ReactNode } from "react";
import { User } from "next-auth";


type AuthContextProps = {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
};

// コンテキストを作成
const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  setUser: (user: User | undefined) => { },
});

// プロバイダーコンポーネント
export const AuthContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | undefined>(undefined);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// コンテキストを使用するためのフック
export const useAuthContext = (): AuthContextProps =>
  useContext<AuthContextProps>(AuthContext);

export default AuthContextProvider;
