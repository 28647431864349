import { FC, useEffect, useState } from "react";
import { URLConstants } from "@/constants/URLConstants";
import { useAppContext } from "@/context/AppContextProvider";
import { usePathname } from "next/navigation";
import useEcho from "@/hooks/useEcho";
import { NotficationEventResponse } from "@/restapi/notification";
import Link from "next/link";
import HomeActiveIcon from "@/components/icons/HomeActiveIcon";
import HomeDeactiveIcon from "@/components/icons/HomeDeactiveIcon";

const HomeLink: FC = () => {
  const pathname = usePathname();
  const [isHomeHovered, setIsHomeHovered] = useState(false);
  const { setSettingMenuProps, isMobile, isModalOpen, setImageDisplayMode } =
    useAppContext();

  const handleClick = () => {
    setSettingMenuProps({
      isMeneuOpen: false,
    });
    // リンクを踏むと画像一覧をデフォルトの3列表示に戻す
    setImageDisplayMode("RankingMode");
  };


  // // 新着投稿通知件数リアルタイム反映処理
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel("channel-notification-new-post")
  //       .listen(
  //         "NotificationNewPostEvent",
  //         (response: NotficationEventResponse) => {
  //           if (response.notifications && 0 < response.notifications.length) {
  //             response.notifications.forEach((n) => {
  //               if (n.userUuid === userId) {
  //                 setCount(n.count);
  //               }
  //             });
  //           }
  //         }
  //       );
  //   }
  // }, [echo]);

  return (
    <Link
      href={URLConstants.home.pathname}
      className={`${isModalOpen ? "linkDisabled" : ""}`}
      onClick={handleClick}
      onMouseEnter={() => setIsHomeHovered(true)}
      onMouseLeave={() => setIsHomeHovered(false)}
    >
      {URLConstants.home.pathname === pathname ||
        (isHomeHovered && !isMobile) ? (
        <HomeActiveIcon />
      ) : (
        <HomeDeactiveIcon />
      )}
    </Link>
  );
};

export default HomeLink;
