"use client";

import { useAppContext } from "@/context/AppContextProvider";
import { useSession } from "next-auth/react";
import { FC } from "react";
import { usePathname } from "next/navigation";
import { URLConstants } from "@/constants/URLConstants";
import SettingHeader from "./SettingHeader";
import HomeHeader from "./HomeHeader";
import "@/app/globals.css";

const Header: FC = () => {
  const { data: session } = useSession();
  const pathname = usePathname();
  const { imageDisplayMode } = useAppContext();

  // マイページ・フォロー・フォロワーページは設定ヘッダー
  let isSettingHeader =
    pathname.includes(`${URLConstants.user.mypage.pathname}`) ||
    pathname.includes(`${URLConstants.user.follower.pathname}/`) ||
    pathname.includes(`${URLConstants.user.following.pathname}/`);
  // それ以外はHomeページと同じヘッダー
  isSettingHeader = !pathname.includes(
    `${URLConstants.user.following.new_posts.pathname}`
  );

  if (
    pathname.includes(`${URLConstants.image.pathname}`) ||
    // 画像一覧が全画面で表示されてる場合は設定メニューを表示しない
    imageDisplayMode === "FullScreenMode"
  ) {
    isSettingHeader = false;
  }

  return isSettingHeader ? <SettingHeader /> : <HomeHeader />;
};

export default Header;
