"use client";

import { useAppContext } from "@/context/AppContextProvider";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { FC } from "react";
import SettingMenu from "../../molecules/settingMenu";
import SignInButton from "../../SignInButton";
import "@/app/globals.css";
import Image from "next/image";

const SettingHeader: FC = () => {
  const { visableMode } = useAppContext();
  const { data: session } = useSession();
  const user = session?.user;

  {
    /* ヘッダー */
  }
  return (
    <div
      className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full min-w-screen-sm flex flex-row-reverse justify-between bg-opacity-50 py-2 settingArea"
      style={{ zIndex: "100" }}
    >
      <div
        className={`logoArea ${visableMode.isShow ? "fade-in" : "fade-out"}`}
      >
        <Link className="text-white" href="/">
          <div className="logoBox">
          <Image
            alt="picsle logo"
            src="/picsle_logo.svg"
            width={300}
            height={300}
            style={{ width: "100%", height: "auto" }} 
            sizes="(max-width: 768px) 100vw, 300px"
            fill={false} 
            priority={false}
          /> 
          </div>
        </Link>
      </div>
      {user ? <SettingMenu /> : <SignInButton />}
    </div>
  );
};

export default SettingHeader;
