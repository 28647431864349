import Link from "next/link";
import ScoringHistoryLinkIcon from "@/components/atoms/scoringHistoryLinkIcon";

export const ScoringHistoryLink = (props: any) => {

  // 通知件数リアルタイム反映処理
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel("channel-notification-scoring-history")
  //       .listen(
  //         "NotificationScoringHistoryEvent",
  //         (response: NotficationEventResponse) => {
  //           let _count = 0;
  //           if (response.notifications && 0 < response.notifications.length) {
  //             response.notifications.forEach((n) => {
  //               if (n.userUuid === userId) {
  //                 _count = n.count;
  //               }
  //             });
  //             setCount(_count);
  //           }
  //         }
  //       );
  //   }
  // }, [echo]);


  return (
    <Link href={`/profile/scoring/history`}>
      <ScoringHistoryLinkIcon />
    </Link>
  );
}


export default ScoringHistoryLink;
