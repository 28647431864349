'use client'
import { useNotifyGetScoringHistory } from "@/hooks/notification/useNotification";
import { useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";

interface ScoringHistoryLinkIconProps {
  color?: string;
  notificationCount?: number;
}

const ScoringHistoryLinkIcon: React.FC<ScoringHistoryLinkIconProps> = ({
  color = "#fff",
}) => {

  const { data: session } = useSession();
  const user = session?.user;
  const userId = user?.id ?? "";
  const [count, setCount] = useState(0);
  const { item } = useNotifyGetScoringHistory(userId);

  useEffect(() => {
    if (item) {
      setCount(item.count);
    }
  }, [item])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className="w-44 h-12"
      style={{
        transform: "scale(0.5)",
      }}
    >
      <defs>
        <style>
          {`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: ${color}; }
          `}
        </style>
      </defs>
      <path
        className="cls-2"
        d="M87.456,63.099c-3.78-30.908-3.2-53.888-32.491-56.154-.259-.02-.464-.234-.464-.494v-1.691c0-2.35-1.718-4.449-4.058-4.672-2.684-.256-4.942,1.848-4.942,4.479v1.885c0,.26-.205.474-.464.494-29.291,2.266-28.711,25.246-32.491,56.154-.817,6.679-5.964,13.726-6.448,21.014-.039.585.413,1.086.999,1.086h85.809c.586,0,1.038-.501.999-1.086-.485-7.288-5.631-14.336-6.448-21.014Z"
      />
      <path
        className="cls-2"
        d="M38.049,89.2c-.313,0-.56.288-.499.595,1.166,5.818,6.288,10.205,12.449,10.205s11.284-4.387,12.449-10.205c.062-.307-.185-.595-.499-.595h-23.901Z"
      />
      <rect className="cls-1" />
      {count > 0 && (
        <g transform="translate(65, 5)">
          {" "}
          {/* 赤丸の位置調整 */}
          <circle cx="25" cy="25" r="25" fill="red" />{" "}
          {/* 赤丸の大きさを調整 */}
          <text x="25" y="32" fontSize="24" fill="white" textAnchor="middle">
            {" "}
            {/* 数字の大きさを調整 */}
            {count}
          </text>
        </g>
      )}
    </svg>
  );
};

export default ScoringHistoryLinkIcon;
