"use client";

import SignOutButton from "@/components/SignOutButton";
import SettingsIcon from "@/components/icons/SettingsIcon";
import EditProfileIcon from "../../icons/EditProfileIcon";
import WebSiteIcon from "../../icons/WebSiteIcon";
import { useAppContext } from "@/context/AppContextProvider";
import "@/components/settingmenu.css";
import CloseIcon from "../../icons/CloseIcon";
import Link from "next/link";
import EnButton from "../../EnButton";
import JpButton from "../../JpButton";
import { useTranslation } from "@/lib/react/i18n";
import SignOutIcon from "../../icons/SignOutIcon";
import PrivacyPolicyIcon from "../../icons/PrivacyPolicyIcon";
import HideMyID from "../../HideMyID";
import TeamOfServiceIcon from "../../icons/TeamOfServiceIcon";
import ScoringHistoryLink from "../scoringHistoryLink";

const SettingMenu = () => {
  const { setSettingMenuProps, settingMenuProps, visableMode, isMobile } =
    useAppContext();
  const { t, locale } = useTranslation();

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setSettingMenuProps({
      isMeneuOpen: !settingMenuProps.isMeneuOpen,
    });
  };

  return (
    <div className={`${visableMode.isShow ? "fade-in" : "fade-out"}`}>
      {!settingMenuProps.isMeneuOpen && (
        <ScoringHistoryLink className="absolute z-30 text-white top-0 p-0 bg-none border-none notificationBtn" />
      )}
      <button
        onClick={toggleMenu}
        className="absolute z-50 text-white top-0 p-0 bg-none border-none settingBtn"
      >
        {settingMenuProps.isMeneuOpen ? (
          <CloseIcon className="w-36 h-12" />
        ) : (
          <SettingsIcon className="absolute z-20 text-white w-36 h-12 left-1 top-2 p-0 bg-none border-none settingBtn" />
        )}
      </button>
      <div
        className={`fixed top-0 right-0 ${
          isMobile ? "w-full" : "w-64"
        } h-full bg-gray-900 shadow-lg z-40 transform ${
          settingMenuProps.isMeneuOpen ? "" : "hidden"
        }`}
      >
        <div className="flex flex-col bg-gray-900">
          <div className="flex flex-row-reverse mt-2 mb-2 settingNaviList">
            <button
              onClick={toggleMenu}
              className="absolute z-50 text-white top-0 p-0 bg-none border-none settingBtn"
            >
              {settingMenuProps.isMeneuOpen ? (
                <CloseIcon className="w-36 h-12" />
              ) : (
                <SettingsIcon className="w-36 h-12" />
              )}
            </button>
          </div>
          <nav className="flex flex-col p-4 pt-8 bg-gray-900 shadow h-screen">
            <div className="text-center settingNavi settingNavi00">
              <div className="flex justify-around">
                <EnButton
                  className={`w-2/5 ${locale === "en" && "lang-button-active"}`}
                />
                <JpButton className={`w-2/5`} />
              </div>
            </div>
            <div className="settingNavi settingNavi01">
              <a
                href="/profile/edit"
                className="py-2 text-lg text-white hover:underline"
              >
                <EditProfileIcon className="inline-block mr-4 text-white w-6 h-6" />
                {t("Edit Profile")}
              </a>
            </div>
            <div className="settingNavi settingNavi02">
              <Link
                className="py-2 text-lg text-white hover:underline"
                href="https://web.picsle.net/"
                target="_blank"
              >
                <WebSiteIcon className="inline-block mr-4 text-white  w-6 h-6" />
                {t("Web Site")}
              </Link>
            </div>
            <div className="settingNavi settingNavi03">
              <Link
                href="/terms-of-service.pdf"
                className="py-2 text-lg text-white hover:underline"
              >
                <TeamOfServiceIcon className="inline-block mr-4 text-white  w-6 h-6" />
                {t("Terms of service")}
              </Link>
            </div>
            <div className="settingNavi settingNavi04">
              <Link
                href="/picsle-privacy-policy.pdf"
                className="py-2 text-lg text-white hover:underline"
              >
                <PrivacyPolicyIcon className="inline-block mr-4 text-white  w-6 h-6" />
                {t("Privacy policy")}
              </Link>
            </div>
            <div className="settingNavi settingNavi05">
              <Link
                className="py-2 text-lg text-white hover:underline"
                href="/welcome"
                target="_blank"
              >
                <SignOutIcon className="inline-block mr-4 text-white  w-6 h-6" />
                {t("Add to homescreen")}
              </Link>
            </div>
            <HideMyID />
            <SignOutButton />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SettingMenu;
