import React, { FC } from "react";
import {
  Card,
  CardContent,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Slider } from "@/components/ui/slider";


const ScoreSlider: FC<{
  score: number | null;
  setScore: React.Dispatch<React.SetStateAction<number | null>>;
}> = ({ score, setScore }) => {

  const handleCommentChange = (e: any) => {
    setScore(e[0]);
  };

  const handleScoreInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const inputEvent = e.nativeEvent as InputEvent;
    if ((inputEvent as any).inputType === 'deleteContentBackward') {
      setScore(null);
    } else {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value >= 0 && value <= 100) {
        setScore(value);
      }
    }
  };

  return (
    <Card className="w-[350px] flex flex-col justify-between">
      <CardContent>
        <div>
          <div className="flex space-y-1.5 p-0">
            <Input
              type="number"
              id="score"
              value={score ?? ''}
              onChange={handleScoreInputChange}
              className="bg-secondary rounded-xl text-center text-md text-black font-normal border-none mr-2 w-25 pt-1 pb-1"
              min={0}
              max={100}
              step={1}
            />
            <Slider
              value={[score ?? 0]}
              max={100}
              step={1}
              className={`${cn("w-[100%]")}`}
              onValueChange={handleCommentChange}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ScoreSlider;