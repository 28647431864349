/**
 * v0 by Vercel.
 * プライバシーポリシーアイコン
 */
export default function PrivacyPolicyIcon (props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
		>
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<g>
				<path className="cls-2" d="M80.249,32.171v-1.053c0-16.459-12.881-30.39-29.333-30.879-17.096-.508-31.165,13.251-31.165,30.235v1.697c0,.552-.448,1-1,1H1c-.552,0-1,.448-1,1v64.829c0,.552.448,1,1,1h98c.552,0,1-.448,1-1V34.171c0-.552-.448-1-1-1h-17.751c-.552,0-1-.448-1-1ZM27.751,30.475c0-12.554,10.45-22.712,23.108-22.233,12.04.455,21.39,10.731,21.39,22.779v1.15c0,.552-.448,1-1,1H28.751c-.552,0-1-.448-1-1v-1.697ZM91,92H9c-.552,0-1-.448-1-1v-48.829c0-.552.448-1,1-1h82c.552,0,1,.448,1,1v48.829c0,.552-.448,1-1,1Z" />
				<path className="cls-2" d="M60.803,59.536c0-6.385-5.539-11.476-12.075-10.73-4.82.55-8.765,4.394-9.425,9.2-.681,4.958,2.009,9.372,6.101,11.3.357.168.596.514.596.909v10.172c0,2.089,1.527,3.955,3.607,4.153,2.386.228,4.393-1.642,4.393-3.981v-10.343c0-.396.239-.742.597-.91,3.666-1.727,6.206-5.448,6.206-9.769Z" />
			</g>
			<rect className="cls-1" width="100" height="100" />
		</svg>
	);
}